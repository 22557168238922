import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "./assets/css/tailwind.css"
import "./assets/css/main.css"
import './assets/fontawesome/css/all.css'

import "bootstrap"; // 從nodeModule中載入Bootstrap
import "bootstrap/dist/css/bootstrap.min.css" // Import css file

import ElementPlus from "element-plus"
import 'element-plus/dist/index.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import common from '@/assets/js/common.js'

//Axios
import axios from 'axios'
import VueAxios from 'vue-axios'
let axios_baseURL = '/api'
axios.defaults.baseURL = axios_baseURL;


//i18n
import i18n from './lang'

import * as echarts from 'echarts'

const route_mapping = {
    4: ['/login', '/logout', '/dashboard', '/testNotify'],
    3: ['/login', '/logout', '/dashboard', '/dataManage', '/testNotify'],
    2: ['/login', '/logout', '/dashboard', '/dataManage', '/setting', '/testNotify'],
    1: ['/login', '/logout', '/dashboard', '/dataManage', '/setting', '/testNotify']
}

//登入狀態驗證
//if backend return unauth
axios.interceptors.response.use(function (response) {
    return response;
    }, function (error) {
    // 401 Unauth
    if(error.response.status === 401 && window.location.pathname != "/login") {
        window.location.href = "/login";
    }
    return Promise.reject(error);
})
router.beforeEach(async(to, from, next)=>{
    if(to.path != '/login' && to.path != '/logout') {   
        await axios.post('/login_status') // Check login_status
        .then(function(data){
            // console.log(data)
            if(data.data["status"] == true){
                store.commit('set_user_data', data.data.data)
                if(route_mapping[store.state.user_data.level].includes(to.path)){
                    next()
                }
                else{
                    next(from.path)
                }
            }
            else{
                next("/login")
            }
        })
        .catch(function(){
            next("/login");
        })
    }
    else{
        store.commit('set_user_data', {})
        next();
    }
})
// 解決 element plus table ResizeObserver 錯誤
// const debounce = (fn, delay) => {
//     let timer = null
//     return function() {
//         let context = this
//         let args = arguments
//         clearTimeout(timer)
//         timer = setTimeout(function(){
//             fn.apply(context, args)
//         }, delay)
//     }
// }
// const _ResizeObserver = window.ResizeObserver
// window.ResizeObserver = class ResizeObserver extends _ResizeObserver{
//     constructor(callback){
//         callback = debounce(callback, 16)
//         super(callback)
//     }
// }


const vm = createApp(App).use(store).use(router).use(ElementPlus).use(VueAxios, axios).use(i18n)
vm.config.globalProperties.echarts = echarts
vm.config.globalProperties.common = common
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    vm.component(key, component)
}
vm.mount('#app')
